@import './variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-btn-primary{
  background: #D52B1E!important;
}
.ant-btn-primary:disabled{
  background: #d9d9d9!important;
}
.ant-pagination .ant-pagination-item-active a{
  color:#D52B1E;
}
.ant-pagination .ant-pagination-item-active a:hover{
  color:#D52B1E;
}
.ant-pagination.ant-table-pagination .ant-pagination.ant-pagination-item-active {
  border-color:#D52B1E;
}
.ant-pagination.ant-table-pagination .ant-pagination.ant-pagination-item-active:hover {
  border-color:#D52B1E;
}
.ant-pagination .ant-pagination-item-active{
  border-color:#D52B1E!important;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
  color: #D52B1E;
  border-color: #D52B1E;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled), .ant-switch.ant-switch-checked{
  background: #D52B1E!important;
}
.ant-table-wrapper .ant-table-column-sorter-down.active, 
.ant-table-wrapper .ant-table-column-sorter-up.active{
  color: #D52B1E
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $theme-color;
  border-color: $theme-color;
}
.ant-btn-link {
  color: $theme-color;
}
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{
  border-color: $theme-color;
}
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: $theme-color;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected{
  color: $theme-color!important;
  background-color: #e6f4ff!important;
}
.ant-table-wrapper .ant-table-filter-trigger.active{
  color: $theme-color!important;
}
.ant-spin .ant-spin-dot-item{
  background-color: $theme-color!important
}
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{
  border-color: $theme-color!important;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: rgba(213, 43, 30, 0.04);
}
.ant-select-selector{
  // border-color: $theme-color!important;
}
.ant-input-outlined:hover{
  border-color: $theme-color;
}

.ant-popover .ant-popover-inner{
  background-color: #f7f9fc;
  min-width: 19rem;
}
.my-modal {
  width: 85%!important;
}
.assignee-popover .ant-popover-inner{
  min-height: 400px;
}