@import '../../variables.scss';

/* reset */
button {
  all: unset;
}

.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label{
  font-size: 0.75rem;
}
.filter-form-group .MuiButtonBase-root.MuiCheckbox-root{
  padding: 2px 10px;
}
.MuiPickersPopper-root{
  z-index: 111;
}

.filter-button{
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, .5);
  border-radius: 8px 0 0 8px;
  padding: 0 6px;
  color: #FFF;
}

.custom-checkbox.ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover {
  .ant-checkbox-inner{
    background-color: $theme-color!important;
  }
}


